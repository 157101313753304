import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Search } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FormProgress from '../shared/FormProgress';
import { IAccountInfo } from './UserSearchTypes';
import { searchForUsers } from './userSearch.data';
import UserSearchTable from './UserSearchTable';
import { updateTitle } from '../reducers/header';

const useStyles = makeStyles({
  container: {
    margin: 20,
  },
  form: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  inputSearch: {
    minWidth: 400,
  },
});

function UserSearchPage() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const urlSearch = new URLSearchParams(location.search).get('search') || ''
  React.useEffect(() => {
    dispatch(updateTitle({
      subtitle: 'User Search',
      title: 'Admin',
    }))
  }, [dispatch])
  const [search, setSearch] = React.useState('')
  const [users, setUsers] = React.useState<IAccountInfo[]>([])
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    if (!urlSearch) return
    setLoading(true)
    setSearch(urlSearch)
    searchForUsers(urlSearch)
      .then(setUsers)
      .finally(() => setLoading(false))
  }, [urlSearch])
  const submitRef = React.createRef<HTMLInputElement>()
  return (
    <div className={classes.container} >
      <FormProgress open={loading} />
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
          history.push('/usersearch?search=' + encodeURIComponent(search))
        }}
      >
        <input type="submit" ref={submitRef} style={{ display: 'none' }} />
        <TextField
          className={classes.inputSearch}
          inputProps={{
            'data-cy': 'search'
          }}
          label='Enter a username, email, first or last name'
          onChange={e => setSearch(e.target.value)}
          required
          value={search}
        />
        <Button
          data-cy='submit'
          onClick={() => submitRef.current?.click()}
          startIcon={<Search />}
        >Search</Button>
      </form>
      <UserSearchTable users={users} />
    </div>
  )
}

export default UserSearchPage
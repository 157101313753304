import Keycloak from "keycloak-js";

const config = process.env.REACT_APP_ENV === 'prod'
  ? require('./keycloak.prod.json')
  : process.env.REACT_APP_ENV === 'stage'
    ? require('./keycloak.stage.json')
    : require('./keycloak.dev.json');
const _kc = new Keycloak(config);

export async function init(onAuthenticated = () => { }) {
  console.debug('kc:init', _kc)
  const authenticated = await _kc.init({
    // onLoad: 'login-required',
    onLoad: 'check-sso',
    checkLoginIframe: false,
    // redirectUri: window.location.href + '?redirectUri=' //+ redirectUri
    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  })
  console.debug('kc:authenticated', authenticated)
  if (authenticated) {
    console.debug('kc:token', _kc.tokenParsed, _kc.resourceAccess)
    onAuthenticated()
  }
  else {
    _kc.login()
  }
}

export function logout() {
  _kc.logout()
}

type TokenParsed = Keycloak.KeycloakTokenParsed & { name: string }

export function getUserName() {
  const tokenParsed = _kc.tokenParsed as TokenParsed
  return tokenParsed?.name
}

export async function getToken() {
  if (_kc.isTokenExpired()) {
    await _kc.updateToken()
    console.debug('kc:token', _kc.tokenParsed, _kc.resourceAccess)
  }
  return _kc.token
}

export function hasResourceRole(role: string, resource: string) {
  return _kc.hasResourceRole(role, resource)
}

const kc = {
  getToken,
  getUserName,
  hasResourceRole,
  init,
  logout,
  adminUrl: () => `${config['auth-server-url']}/admin/realms/${config.realm}`,
}

export default kc
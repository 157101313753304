import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';

import './App.css';

const useStyles = makeStyles(theme => ({
    contactSupportSpan: {
        whiteSpace: 'nowrap', 
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
          fontSize: '12px'
        }
      },
    contactNumber: {
        whiteSpace: 'nowrap',
        fontSize: '14px'
    }
}))

function AppFooter() {
  const classes = useStyles()
  return (
    <Box bottom='10px' paddingBottom={5}>
        <Box display='flex' flexDirection='row' justifyContent='space-between' padding={2} paddingBottom={0}>
            <Typography>
                <span className={classes.contactSupportSpan} >Having issues? &nbsp;</span>
                <span className={classes.contactSupportSpan} >Please contact our support team.</span>
            </Typography>
        </Box>
        <Box display='flex' flexDirection='row' flexWrap='wrap' padding={2} paddingBottom={0} gridGap='15px'>
            <Box display='flex' flexDirection='column'>
                <Typography>
                    <span className={classes.contactSupportSpan} >Timer Support: </span>
                    <span className={classes.contactNumber} >(812)759-7877</span>
                </Typography>
                <Typography variant='caption'>
                    esupport@chronotrack.com
                </Typography>
            </Box>
            <Divider orientation='vertical' flexItem/>
            <Box display='flex' flexDirection='column'>
                <Typography>
                    <span className={classes.contactSupportSpan} >EU Support: </span>
                    <span className={classes.contactNumber} >+31 30 77 40 050</span>
                </Typography>
                <Typography variant='caption'>
                    eusupport@chronotrack.eu
                </Typography>
            </Box>
            <Hidden smDown>
                <Divider orientation='vertical' variant='middle' flexItem/>
                <Box display='flex' flexDirection='column' alignSelf='end'>
                    <Link href='https://knowledge.chronotrack.com' target='_blank'>https://knowledge.chronotrack.com</Link>
                </Box>
            </Hidden>
        </Box>
    </Box>
  )
}

export default AppFooter

import * as keycloak from '../keycloak';

export async function getAuthorizedList() {
  const response = await fetch(process.env.REACT_APP_IOT_API_URL + '/authorization/list', {
    headers: {
      Authorization: 'Bearer ' + await keycloak.getToken()
    }
  });

  if (response.status === 200) {
    const text = await response.text();
    return JSON.parse(text);
  }
  throw new Error(response.statusText);
}

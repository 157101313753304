import {
  Grid,
  makeStyles,
  Snackbar,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import React from 'react'
import { useDispatch } from 'react-redux';
import { updateLastUpdated, updateTitle } from '../reducers/header';
import FormProgress from '../shared/FormProgress';
import { getAuthorizedList } from './remoteControl.data';
import RemoteControlDialog from './RemoteControlDialog';
import RemoteControlTable from './RemoteControlTable';
import { DialogType } from '../types'
import AddControllerToolbar from '../shared/AddControllerToolbar';
import { ERROR_500 } from '../constants';

const useStyles = makeStyles({
  container: {
    margin: 20,
  }
});

function RemoteControlPage() {
  const dispatch = useDispatch()
  const classes = useStyles();

  const [openDialog, setDialogOpen] = React.useState<DialogType | null>(null)
  const [error, setError] = React.useState('')
  const [asOf, setAsOf] = React.useState(0)
  const [macs, setMacs] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  const fetchAuthorized = React.useCallback(() => {
    setLoading(true)
    getAuthorizedList()
      .then(json => {
        setMacs(json.controllers)
        dispatch(updateLastUpdated())
      })
      .catch(err => { console.error(err); setError(ERROR_500) })
      .finally(() => setLoading(false))
  }, [dispatch])

  React.useEffect(() => {
    dispatch(updateTitle({
      subtitle: 'Controllers',
      title: 'Remote Control',
    }))
  }, [dispatch])

  React.useEffect(() => {
    fetchAuthorized()
    const fetchInterval = setInterval(fetchAuthorized, 60000)
    return () => {
      clearInterval(fetchInterval);
    }
  }, [asOf, fetchAuthorized])
  console.debug('macs', macs)
  
  return (
    <div className={classes.container}>
      <FormProgress open={loading} />
      <AddControllerToolbar setDialogOpen={setDialogOpen} onUpdate={fetchAuthorized} display={{ccs: false, remote: true}} />
      <Grid container>
        <Grid item xs={12}>
          <RemoteControlTable macs={macs} />
        </Grid>
      </Grid>
      <Snackbar
        open={!!error}
      >
        <Alert severity='error'>
          <Typography>
            {error}
          </Typography>
        </Alert>
      </Snackbar>
      <RemoteControlDialog 
        onSuccess={() => setAsOf(new Date().getTime())} 
        open={openDialog === DialogType.REMOTE}
        setOpen={setDialogOpen} 
      />
    </div>
  )
}

export default RemoteControlPage



import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import AppRoot from './AppRoot';
import { useDarkTheme } from './useDarkTheme';
import { createGenerateClassName, StylesProvider } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

export default function ThemeWrapper() {
  const darkTheme = useDarkTheme();

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <StylesProvider
        generateClassName={generateClassName}
      >
        <AppRoot />
      </StylesProvider>
    </ThemeProvider>
  );
}

import keycloak from '../keycloak';

export async function searchForUsers(search: string) {
  const response = await fetch(keycloak.adminUrl() + '/users?search=' + encodeURIComponent(search), {
    headers: {
      Authorization: 'Bearer ' + await keycloak.getToken()
    }
  });

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  const text = await response.text();
  return JSON.parse(text);
}

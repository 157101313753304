import keycloak from '../keycloak';
import { IAccountInfo } from '../userSearch/UserSearchTypes';

export async function getKeycloakUserInfo(userId: string) {
  const response = await fetch(keycloak.adminUrl() + '/users/' + userId, {
    headers: {
      Authorization: 'Bearer ' + await keycloak.getToken()
    }
  });

  if (response.status === 200) {
    const text = await response.text();
    const json = JSON.parse(text);
    return json as IAccountInfo;
  }
  throw new Error(response.statusText);
}

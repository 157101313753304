import React from 'react';
import {
  BrowserRouter,
  Switch as RouterSwitch,
  Route
} from 'react-router-dom'
import { makeStyles } from '@material-ui/core';

import './App.css';
import AppDrawer, { DRAWER_WIDTH } from './AppDrawer';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter'
import RemoteControlPage from '../remoteControl/RemoteControlPage';
import UserSearchPage from '../userSearch/UserSearchPage';
import CcsControllersPage from '../ccs/CcsControllersPage';
import UserInfoPage from '../userInfo/UserInfoPage';
import ProtectedRoute from './ProtectedRoute';
import NotFoundPage from './NotFoundPage';

const useStyles = makeStyles(theme => ({
  main: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}))

function AppRoot() {
  const classes = useStyles()
  return (
    <div className="App">
      <BrowserRouter>
        <AppHeader />
        <AppDrawer />
        <main className={classes.main}>
          <div className={classes.toolbar} />
          <RouterSwitch>
            <Route path="/" exact={true} component={CcsControllersPage} />
            <Route path="/controllers" component={CcsControllersPage} />
            <Route path="/remotecontrol" component={RemoteControlPage} />
            <ProtectedRoute path="/userinfo/:userId" component={UserInfoPage} resource='realm-management' role='manage-users' />
            <ProtectedRoute path="/usersearch" component={UserSearchPage} resource='realm-management' role='manage-users' />
            <Route path="*" component={NotFoundPage} />
          </RouterSwitch>
          <AppFooter />
        </main>
      </BrowserRouter>
    </div>
  );
}

export default AppRoot;

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  createTheme,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import { ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import './App.css';
import CTLiveLogo from './CTLiveLogo';
import keycloak from '../keycloak';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import { setOpen } from '../reducers/open';

export const DRAWER_WIDTH = 250

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0
  },
  drawerPaper: {
    backgroundColor: 'rgb(44, 49, 78)',
    width: DRAWER_WIDTH
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    padding: theme.spacing(3, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
    paddingBottom: '65px'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: '30px',
    '&:hover': {
      backgroundColor: '#141723',
      color: '#00B0FF'
    }
  }
}))

function AppDrawer() {
  const history = useHistory()
  const classes = useStyles()
  const theme = React.useMemo(
    () => createTheme({
      palette: {
        type: 'dark',
      },
    }), [])
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const open = useSelector<{ open: { drawer: boolean } }, boolean>(_ => _.open.drawer)
  const dispatch = useDispatch()
  const handleCloseDrawer = () => dispatch(
    setOpen({
      key: 'drawer',
      open: false
    }))
  return (
    <nav className="AppNav">
      <ThemeProvider theme={theme}>
        <Drawer
          anchor='left'
          classes={{
            paper: classes.drawerPaper
          }}
          className={classes.drawer}
          keepMounted
          open={mdDown ? open : true}
          variant={mdDown ? 'temporary' : 'persistent'}
        >
          <div className={classes.drawerHeader}>
            <Hidden lgUp>
              <IconButton
                // color="primary"
                onClick={handleCloseDrawer}
              >
                <Close />
              </IconButton>
            </Hidden>
            <CTLiveLogo style={{ alignSelf: 'center' }} />
          </div>
          <List>
            <ListItem
              button
              className={classes.listItem}
              onClick={() => {
                handleCloseDrawer()
                history.push('/controllers')
              }}
            >
              <Typography><strong>Controller Status</strong></Typography>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              onClick={() => {
                handleCloseDrawer()
                history.push('/remotecontrol')
              }}
            >
              <Typography><strong>Remote Control</strong></Typography>
            </ListItem>
            {window.name !== '_chronotrackHub' ? null :
              <ListItem
                button
                className={classes.listItem}
                onClick={() => {
                  window.close()
                }}
              >Back to Fusion</ListItem>
            }
          </List>
          {!keycloak.hasResourceRole('manage-users', 'realm-management')
            ? null
            : <>
              <Box marginLeft={1}>
                <Typography color='textPrimary' variant='h6'>Admin</Typography>
                <Divider />
              </Box>
              <List disablePadding>
                <ListItem
                  button
                  className={classes.listItem}
                  data-cy='navUserSearch'
                  onClick={() => {
                    handleCloseDrawer()
                    history.push('/usersearch')
                  }}
                >
                  <ListItemText secondary='User Search' />
                </ListItem>
              </List>
            </>}
        </Drawer>
      </ThemeProvider>
    </nav >
  )
}

export default AppDrawer

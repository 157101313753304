import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import MaterialTable from 'material-table';
import React from 'react'
import { Controller } from './ccsControllers.data';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PhonelinkRing from '@material-ui/icons/PhonelinkRing';


function msToTime(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const hrs = (hours < 10) ? "0" + hours : hours;
  const min = (minutes < 10) ? "0" + minutes : minutes;
  const sec = (seconds < 10) ? "0" + seconds : seconds;

  if (hours > 0) { return `${hrs}:${min} HRS` }
  else { return `${min}:${sec} MIN` }
}

function ControllersTable({
  controllers
}: {
  controllers: Controller[],
}) {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <MaterialTable
      
      columns={[
        {
          title: 'INFO', hidden: !smDown, render: ({ enabled, mac, serial }) => (
            <>
              <Box hidden={!enabled}>
                <Typography style={{ whiteSpace: 'nowrap' }}>Serial: {serial}</Typography>
                <Typography style={{ whiteSpace: 'nowrap' }}>Mac: {mac}</Typography>
              </Box>
              <Box hidden={enabled}>
                <Typography style={{ whiteSpace: 'nowrap' }}>{mac}</Typography>
              </Box>
            </>
          )
        },
        { title: 'SERIAL', field: 'serial', type: 'numeric', align: 'left', hidden: smDown },
        { title: 'MAC ADDRESS', field: 'mac', hidden: smDown },
        {
          title: 'CONFIG', hidden: !smDown, render: ({ event, point, timeSource }) => (
            <>
              <Typography style={{ whiteSpace: 'nowrap' }}>Event: {event}</Typography>
              <Typography style={{ whiteSpace: 'nowrap' }}>Point: {point}</Typography>
              <Typography style={{ whiteSpace: 'nowrap' }}>Time: {timeSource}</Typography>
            </>
          )
        },
        { title: 'EVENT', field: 'event', hidden: smDown },
        { title: 'POINT', field: 'point', hidden: smDown },
        { title: 'TIME SOURCE', field: 'timeSource', lookup: { gps: "GPS", ntp: "NTP", manual: "Manual" }, hidden: smDown },
        {
          title: 'CCS STATUS', field: 'onlinedate', defaultSort: 'desc', render: ({ online, onlinedate }) => (
            <>
              <Box hidden={!online}>
                <Box display='flex' color='#5FC2A2' gridGap='5px'>
                  <FiberManualRecordIcon />
                  <Typography style={{ whiteSpace: 'nowrap' }}>ONLINE</Typography>
                </Box>
                <Typography variant='caption'>{msToTime(Date.now() - +(onlinedate))}</Typography>
              </Box>
              <Box hidden={online}>
                <Box display='flex' color='#BC3A3C' gridGap='5px'>
                  <FiberManualRecordIcon fontSize='small' />
                  <Typography>OFFLINE</Typography>
                </Box>
                <Box display='flex' flexDirection='column'>
                  <Typography variant='caption'>{onlinedate.toLocaleDateString()}</Typography>
                  <Typography variant='caption'>{onlinedate.toLocaleTimeString()}</Typography>
                </Box>
              </Box>
            </>
          )
        },
        {
          title: 'CONFIG STATUS', field: 'enabled', render: ({ enabled, type }) => (
            <>
              <Box hidden={!enabled}>
                <Box display='flex' color='#5FC2A2' gridGap='5px'>
                  <Box hidden={type === 'Unknown'}>
                    <FiberManualRecordIcon />
                  </Box>
                  <Box hidden={!(type === 'Unknown')}>
                    <PhonelinkRing />
                  </Box>
                  <Typography style={{ whiteSpace: 'nowrap' }}>CONFIGURED</Typography>
                </Box>
              </Box>
              <Box hidden={enabled}>
                <Box display='flex' color='#BC3A3C'>
                  <FiberManualRecordIcon fontSize='small' />
                  <Typography>NONE</Typography>
                </Box>
              </Box>
            </>
          )
        }
      ]}
      data={controllers.map(x => ({
        ...x,
        onlinedate: x.onlinedate ? new Date(x.onlinedate * 1000) : new Date(x.updated),
        timeSource: x.desired?.timeSource
      })).filter(x => {
        return (x.code || x.enabled)
      })}
      options={{
        // filtering: true,
        paging: true,
        sorting: true,
        toolbar: false,
        thirdSortClick: false
      }}
    />
  )
}

export default ControllersTable